/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@300;400;700;800&display=swap");

body {
  font-family: "Nunito", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, "sans-serif" !important;
  overflow-x: hidden !important;
}

.flex-start {
  @apply flex items-center justify-start;
}

mjx-math {
  white-space: normal !important;
}

mjx-container[jax="CHTML"][display="true"] {
  text-align: inherit !important;
  display: inline-block !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #adaaaa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
